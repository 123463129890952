import React, {useState, useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPowerOff, faHome, faEnvelope, faRoute, faUserCog, faKey, faServer, faDatabase, faBox, faCreditCard, faShield, faClock} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faInstagram, faYoutube, faDigitalOcean} from "@fortawesome/free-brands-svg-icons"

// Import components
import Index from "./components/index/index.jsx";
import Messages from "./components/messages/messages.jsx";
import Quests from "./components/quests/quests.jsx";
import Users from "./components/users/users.jsx";
import Log from "./components/log/log.jsx";
import Login from "./components/login/login.jsx";
import Console from "./components/console/console.jsx";
import Server from "./components/server/server.jsx";
import Orders from "./components/orders/orders.jsx";
import Restrictions from "./components/restrictions/restrictions.jsx";

// Import component css
import "./components/index/index.css";
import "./components/messages/messages.css";
import "./components/quests/quests.css";
import "./components/users/users.css";
import "./components/log/log.css";
import "./components/login/login.css";
import "./components/console/console.css";
import "./components/server/server.css";
import "./components/orders/orders.css";
import "./components/restrictions/restrictions.css";

// Pre-determine initial states to prevent an infinite rendering loop
let authorizedPred = false;
let accessLevelPred = 0;
let timeLeftPred = 1800;

if (sessionStorage.sessionId) {
    const refreshed = parseInt(sessionStorage.refreshed) || 0;
    
    // Get current and last session refresh time in seconds
    const now = Math.floor(new Date() / 1000);
    const then = refreshed;
    
    // Check if the session has expired (30 minutes)
    if (now > (then + 1800)) {
        authorizedPred = false;
        sessionStorage.removeItem("sessionId");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("accessLevel");
        sessionStorage.removeItem("refreshed");
    } else {
        authorizedPred = true;
    }
}

function App() {
    const [authorized, setAuthorized] = useState(authorizedPred);
    const [accessLevel, setAccessLevel] = useState(accessLevelPred);
    const [timeLeft, setTimeLeft] = useState(timeLeftPred);
    const [showSessionExpirationWarning, setSessionExpirationWarning] = useState(false);
    
    // Check if the users session is about to expire
    useEffect(() => {
        setInterval(() => {
            // Only run if a session exists
            if (sessionStorage.sessionId) {
                const now = Math.floor(new Date() / 1000);
                const then = parseInt(sessionStorage.refreshed);
                const timeLeft = now - (then + 1800);
                const timeLeftAbsolute = Math.abs(timeLeft);
                let seconds = timeLeftAbsolute % 60;
                // Add a zero before single digit seconds
                if (seconds < 10) { seconds = "0" + seconds}
                // Add a zero before single digit minutes
                let minutes = Math.floor(timeLeftAbsolute / 60);
                if (minutes < 10) { minutes = "0" + minutes}
                setTimeLeft(minutes + ":" + seconds);
                
                // Display the timer if there are lest than 5 minutes left in the session
                if (timeLeft > -300) {
                    // Don't re-render the app if expiration warning is already set is already set
                    if (showSessionExpirationWarning === false) {
                        setSessionExpirationWarning(true);
                        console.log('Ran');
                    }
                } else {
                    // Don't re-render the app if expiration warning is already set is already set
                    if (showSessionExpirationWarning === true) {
                        setSessionExpirationWarning(false);
                    }
                }
                
                // Log user out if time left is 0
                if (timeLeft > -1) {
                    deAuthorize();
                }
            }
        }, 1000);
    }, []);
    
    function handleAuthorization() {
        setAccessLevel(parseInt(sessionStorage.accessLevel));
        setAuthorized(true);
    }
    
    function deAuthorize() {
        sessionStorage.removeItem("sessionId");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("accessLevel");
        sessionStorage.removeItem("refreshed");
        setAuthorized(false);
    }

    // Display navigation and pages if logged in
    if (authorized) {
        return (
            <Router>
                <nav>
                    <img src={require("./images/icons/rocket.svg")} alt="logo" />

                    <h4>Our System</h4>
                    <Link to="/"><FontAwesomeIcon icon={faHome}/> Index</Link>
                    <Link to="/messages"><FontAwesomeIcon icon={faEnvelope}/> Messages</Link>
                    <Link to="/orders"><FontAwesomeIcon icon={faBox}/> Orders</Link>
                    <Link to="/restrictions"><FontAwesomeIcon icon={faClock}/> Restrictions</Link>
                    <Link to="/quests"><FontAwesomeIcon icon={faRoute}/> Quests</Link>
                    <Link to="/users"><FontAwesomeIcon icon={faUserCog}/> Users</Link>
                    <Link to="/console"><FontAwesomeIcon icon={faDatabase}/> Console</Link>
                    <Link to="/server"><FontAwesomeIcon icon={faServer}/> Server</Link>
                    <Link to="/log"><FontAwesomeIcon icon={faKey}/> Log</Link>

                    <h4 className="margin-top">Quick Links</h4>
                    <a href="https://www.facebook.com/city-quest" alt="Facebook" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare}/> Facebook</a>
                    <a href="https://www.youtube.com/channel/city-quest" alt="Youtube" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube}/> Youtube</a>
                    <a href="https://www.instagram.com/city-quest" alt="Instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/> Instagram</a>
                    <a href="https://cloud.digitalocean.com/login" alt="Digital Ocean" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDigitalOcean}/> Digital Ocean</a>
                    <a href="https://portal.dibspayment.eu/dashboard" alt="DIBS Payments" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCreditCard}/> DIBS Payments</a>
                </nav>
                
                <div className="content">
                    {showSessionExpirationWarning ? <div className="time-left">{timeLeft}</div> : null}
                    <button type="button" className="btn-power" onClick={deAuthorize}><FontAwesomeIcon icon={faPowerOff}/></button>
                    <Switch>
                        <Route path="/" exact={true} component={Index}></Route>
                        <Route path="/messages" component={Messages}></Route>
                        <Route path="/quests" component={Quests}></Route>
                        <Route path="/users" component={Users}></Route>
                        <Route path="/log" component={Log}></Route>
                        <Route path="/Server" component={Server}></Route>
                        <Route path="/console" component={Console}></Route>
                        <Route path="/orders" component={Orders}></Route>
                        <Route path="/restrictions" component={Restrictions}></Route>
                    </Switch>
                </div>
            </Router>
        );
    }
    
    // Display login screen if not authorized
    return <Login onAuthorization={handleAuthorization}/>;
}

export default App;
