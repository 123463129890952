import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowAltCircleLeft} from '@fortawesome/free-solid-svg-icons';

function Server() {
    // Initialize canvas references
    const canvasSystemLoadRef = React.useRef(null)
    const canvasSocketClientsRef = React.useRef(null)
    const canvasSystemLoadWidth = 615;
    const canvasSystemLoadHeight = 320;
    const canvasSocketClientsWidth = 615;
    const canvasSocketClientsHeight = 320;

    useEffect(() => {
        // Initialize canvas and their contexts
        const canvasSystemLoad = canvasSystemLoadRef.current;
        const canvasSocketClients = canvasSocketClientsRef.current;
        const contextSystemload = canvasSystemLoad.getContext('2d');
        const contextSocketClients = canvasSocketClients.getContext('2d');
        
        // Load stats on page open
        getSystemInformation();
        
        // Refresh current load every 5 seconds
        const getStatisticsInterval = setInterval(() => {
            getSystemStatistics(contextSystemload, contextSocketClients, canvasSystemLoad, canvasSocketClients);
        }, 1000);
        
        // Clean-up on component unmount
        return () => {
            clearInterval(getStatisticsInterval);
        }
    }, []);

    const [serverConfig, setServerConfig] = useState({
        "winserver": null,
        "devproxy": null,
        "debugMessages": null,
        "logToDisk": null,
        "safeMode": null,
        "apiPort": null,
        "socketsPort": null,
        "socketsUpdateInterval": null,
        "socketsStatisticsRate": null
    });

    const [serverStatistics, setServerStatistics] = useState({
        "errorLogSize": null,
    });
    
    const [serverSystem, setServerSystem] = useState({
        "os": null,
        "memory": null,
        "cpuModel": null,
        "cpuSpeed": null,
        "diskSpace": null,
        "diskSpaceUsage": null
    });
    
    const [systemLoad, setSystemLoad] = useState([]);
    const [socketClients, setSocketClients] = useState([]);
    
    function getSystemInformation() {
        axios.get("api/admin/system/information", {
            params: {
                sessionId: sessionStorage.sessionId
            }
        }).then((response) => {
            // Deal with the retrieved data
            let data = response.data.data;
            
            // Compile config
            const config = {
                "winserver": data.config.winserver + "",
                "devproxy": data.config.devproxy + "",
                "debugMessages": data.config.debugMessages + "",
                "logToDisk": data.config.logToDisk + "",
                "safeMode": data.config.safeMode + "",
                "apiPort": data.config.apiPort + "",
                "socketsPort": data.config.socketsPort + "",
                "socketsUpdateInterval": data.config.socketsUpdateInterval + " ms",
                "socketsStatisticsRate": "every " + data.config.socketsStatisticsRate + " intervals"
            }
            
            // Compile statistics
            const statistics = {
                "errorLogSize": data.statistics.errorLogSize
            };
            
            // Set socket clients
            setSocketClients(data.statistics.socketsOverTime);
            
            // Set state to the retrieved data
            setServerConfig(config);
            setServerStatistics(statistics);
            setServerSystem(data.system);
            
            // Update session refresh time
            sessionStorage.refreshed = response.data.refreshed;
        }, (error) => {
            // Handle errors...
        });
    }
    
    function getSystemStatistics(contextSystemLoad, contextSocketClients, canvasSystemLoad, canvasSocketClients) {
        axios.get("api/admin/system/statistics", {
            params: {
                sessionId: sessionStorage.sessionId
            }
        }).then((response) => {
            // Deal with the retrieved data
            let data = response.data.data;

            // Update system load data
            if (systemLoad.length > 9) {
                systemLoad.shift();
                systemLoad.push({"cpuLoad": data.cpuLoad, "memoryUsage": data.memoryUsage});
            } else {
                systemLoad.push({"cpuLoad": data.cpuLoad, "memoryUsage": data.memoryUsage});
            }
            
            setSystemLoad([{"cpuLoad": data.cpuLoad, "memoryUsage": data.memoryUsage}, {"cpuLoad": data.cpuLoad, "memoryUsage": data.memoryUsage}, {"cpuLoad": data.cpuLoad, "memoryUsage": data.memoryUsage}]);
            
            // Update socket clients data
            //socketClientsOverTime
            
            // Draw system load
            const spacing = 50; // Space between readings on the chart
            const height = canvasSystemLoadHeight; // Canvas height
            let x = 15; // Initial drawing position
            const offsetX = 15;
            const offsetY = 25;
            
            // Clear previous drawings on the canvas
            contextSystemLoad.clearRect(0, 0, canvasSystemLoad.width, canvasSystemLoad.height);
            
            // Draw y axis
            contextSystemLoad.beginPath();
            contextSystemLoad.moveTo(offsetX + 10, offsetY + 10);
            contextSystemLoad.lineTo(offsetX + 10, 295);
            contextSystemLoad.stroke();
            
            // Draw x axis
            contextSystemLoad.beginPath();
            contextSystemLoad.moveTo(offsetX + 10, offsetY + 270);
            contextSystemLoad.lineTo(585, offsetY + 270);
            contextSystemLoad.stroke();
            
            // Draw readings
            for (let i = 0; i < systemLoad.length; i++) {
                if (i !== 0) {
                    contextSystemLoad.beginPath();
                    contextSystemLoad.moveTo(x + offsetX, 100 * height / 100 - systemLoad[i - 1].cpuLoad * height / 100 - offsetY);
                    contextSystemLoad.lineTo(x + spacing + offsetX, 100 * height / 100 - systemLoad[i].cpuLoad * height / 100 - offsetY);
                    contextSystemLoad.stroke();
                }
                
                // Add text value
                contextSystemLoad.font = "16px Tahoma";
                contextSystemLoad.fillStyle = "red";
                contextSystemLoad.textAlign = "center";
                contextSystemLoad.fillText(systemLoad[i].cpuLoad, x + spacing + offsetX, 100 * height / 100 - systemLoad[i].cpuLoad * height / 100 - 6 - offsetY);
                
                // Move cursor forward
                x = x + spacing;
            }
            
            // Draw socket clients
            
            // Update session refresh time
            sessionStorage.refreshed = response.data.refreshed;
        }, (error) => {
            // Handle errors...
        });
    }
    
    // View
    return (
        <div className="box-content">
            <h1>Server</h1>
            <div className="box">
                <div className="box-inline">
                    <header>Server configuration</header>
                    <table>
                        <thead>
                            <tr><th>Option</th><th>Value</th></tr>
                        </thead>
                        <tbody>
                            <tr><td>WINSERVER:</td><td>{serverConfig.winserver}</td></tr>
                            <tr><td>DEVPROXY:</td><td>{serverConfig.devproxy}</td></tr>
                            <tr><td>DEBUG_MESSAGES:</td><td>{serverConfig.debugMessages}</td></tr>
                            <tr><td>LOG_TO_DISK:</td><td>{serverConfig.logToDisk}</td></tr>
                            <tr><td>SAFE_MODE:</td><td>{serverConfig.safeMode}</td></tr>
                            <tr><td>API port:</td><td>{serverConfig.apiPort}</td></tr>
                            <tr><td>Sockets port:</td><td>{serverConfig.socketsPort}</td></tr>
                            <tr><td>Sockets update interval:</td><td>{serverConfig.socketsUpdateInterval}</td></tr>
                            <tr><td>Sockets statistics rate:</td><td>{serverConfig.socketsStatisticsRate}</td></tr>
                        </tbody>
                    </table>
                </div>
                
                <div className="box-inline">
                    <header>System information</header>
                    <table>
                        <thead>
                            <tr><th>Type</th><th>Value</th></tr>
                        </thead>
                        <tbody>
                            <tr><td>OS:</td><td>{serverSystem.os}</td></tr>
                            <tr><td>CPU Model:</td><td>{serverSystem.cpuModel}</td></tr>
                            <tr><td>CPU Speed:</td><td>{serverSystem.cpuSpeed}</td></tr>
                            <tr><td>Memory:</td><td>{serverSystem.memory}</td></tr>
                            <tr><td>Disk space:</td><td>{serverSystem.diskSpace}</td></tr>
                            <tr><td>Disk space in use:</td><td>{serverSystem.diskSpaceUsage}</td></tr>
                        </tbody>
                    </table>
                </div>

                <div className="box-inline">
                    <header>Other information</header>
                    <table>
                        <thead>
                            <tr><th>Type</th><th>Value</th></tr>
                        </thead>
                        <tbody>
                            <tr><td>Error log size:</td><td>{serverStatistics.errorLogSize} MB</td></tr>
                        </tbody>
                    </table>
                </div>
                
                <div className="box-wide">
                    <header>System load</header>
                    <canvas className="canvas-system-load" ref={canvasSystemLoadRef} width={canvasSystemLoadWidth} height={canvasSystemLoadHeight}/>
                </div>
                
                <br/>
                
                <div className="box-wide">
                    <header>Socket clients over time</header>
                    <canvas className="canvas-socket-clients" ref={canvasSocketClientsRef} width={canvasSocketClientsWidth} height={canvasSocketClientsHeight}/>
                </div>
                
                <br/>
                
                <div className="box-wide">
                    <header>Server error log</header>
                    <ul className="list-log">
                        <li>Press load to get the error log</li>
                    </ul>
                </div>
                <button className="btn btn-load" type="button">Load <FontAwesomeIcon icon={faArrowAltCircleLeft}/></button>
            </div>
        </div>
    );
}

export default Server;