import React from 'react';

function Index() {
    // View
    return (
        <div className="box-content">
            <h1>Index</h1>
            <div className="box">
                <h2>Welcome to the Launch Pad, our system management tool.</h2>
            </div>
        </div>
    );
}

export default Index;