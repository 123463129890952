import React from 'react';

function Log() {
    return (
        <div className="box-content">
            <h1>Log</h1>
            <div className="box">Security log</div>
        </div>
    );
}

export default Log;