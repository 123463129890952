import React from 'react';

function Users() {
    return (
        <div class="box-content">
            <h1>Users</h1>
            <div class="box">Users</div>
        </div>
    );
}

export default Users;