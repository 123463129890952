import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowAltCircleRight, faPlus, faMinus, faTrash, faOutdent} from '@fortawesome/free-solid-svg-icons';
import {faFileText} from "@fortawesome/free-brands-svg-icons";
import Flatpickr from "react-flatpickr";

// Import styles
import "flatpickr/dist/themes/material_green.css";

function Orders() {
    // Loadable stuff, error and UI stuff
    const [error, setError] = useState("");
    const [orders, setOrders] = useState([]);
    const [quests, setQuests] = useState([]);
    const [showCreateOrderView, setShowCreateOrderView] = useState(false);
    
    // Create new order stuff
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [cvr, setCVR] = useState("");
    const [clientType, setClientType] = useState("");
    const [participants, setParticipants] = useState(0);
    const [date, setDate] = useState("");
    const [questId, setQuestId] = useState("");
    const [questTitle, setQuestTitle] = useState("");
    const [contents, setContents] = useState([]);

    // Manual paymend it => Downtown stuff
    const [paymentId, setPaymentId] = useState([]);

    useEffect(() => {
        getOrders();
        getQuests();
    }, []);

    function getOrders() {
        axios.get("api/admin/orders", {
            params: {
                sessionId: sessionStorage.sessionId
            }
        }).then((response) => {
            // Deal with the retrieved data
            const data = response.data.data;
            
            // Parse the retrieved data
            let orderData = [];
            
            for (let i = 0; i < data.length; i++) {
                const order = {
                    "id": data[i].id,
                    "paymentId": data[i].payment_id,
                    "clientType": data[i].client_type,
                    "details": JSON.parse(data[i].details),
                    "questData": JSON.parse(data[i].quest_data),
                    "email": data[i].email,
                    "telephone": data[i].telephone,
                    "cvr": data[i].cvr,
                    "paymentStatus": data[i].payment_status,
                    "dateOfCreation": data[i].date_of_creation,
                    "manualEntry": data[i].manual_entry,
                }
                
                orderData.push(order);
            }
            
            // Set state to the retrieved data
            setOrders(orderData);
            
            // Update session refresh time
            sessionStorage.refreshed = response.data.refreshed;
        }, (error) => {
            // Handle errors...
        });
    }
    
    function getQuests() {
        axios.get("api/admin/quests", {
            params: {
                sessionId: sessionStorage.sessionId
            }
        }).then((response) => {
            // Deal with the retrieved data
            let data = response.data.data;
            
            // Set state to the retrieved data
            setQuests(data);
            
            // Update session refresh time
            sessionStorage.refreshed = response.data.refreshed;
        }, (error) => {
            // Handle errors...
        });
    }
    
    function createOrder() {
        axios.post("api/admin/sql", {
            "contents": contents,
            "clientType": clientType,
            "cvr": cvr,
            "email": email,
            "sessionId": sessionStorage.sessionId
        }).then((response) => {
            // Create a response table
            const data = response.data.data;
            
            // Get payment id
            
            // Add the created order to the orders list
            
            // Set session refresh time
            sessionStorage.refreshed = response.data.refreshed;
        }, (error) => {
            setError(error + "<br> Server said: " + JSON.stringify(error.response.data));
        });
    }
    
    function showDetails(index) {
        console.log(index);
    }
    
    function selectQuest(title) {
        // Find the quest with the title from selection
        for (let i = 0; i < quests.length; i++) {
            if (quests[i].title === title) {
                // Set selected quest id
                setQuestId(quests[i].id);
                setQuestTitle(title);
                break;
            }
        }
    }
    
    function changeCreateOrderVisibility() {
        if (showCreateOrderView === true) {
            setShowCreateOrderView(false);
            return;
        }
        
        setShowCreateOrderView(true);
    }
    
    function deleteOrder(orderId) {
        console.log("order id is: " + orderId);
    }

    return (
        <div className="box-content">
            <h1>Orders</h1>
            <div className="box">
                <div className="box-wide box-create-order">
                    <header>Create a new order {showCreateOrderView
                        ? (<button type="button" className="btn" onClick={() => {changeCreateOrderVisibility()}}><FontAwesomeIcon icon={faMinus}/></button>)
                        : (<button type="button" className="btn" onClick={() => {changeCreateOrderVisibility()}}><FontAwesomeIcon icon={faPlus}/></button>)}</header>
                    {showCreateOrderView && (
                    <div>
                        <fieldset>
                            <legend>Client's details</legend>
                            <label>Email *</label>
                            <input type="email" placeholder="Email*" onChange={e => setEmail(e.target.value)}/>
                            <label>Telephone</label>
                            <input type="telephone" placeholder="Telephone" onChange={e => setTelephone(e.target.value)}/>
                            <label>Client type *</label>
                            <select defaultValue="selectClientType" onChange={e => setClientType(e.target.value)}>
                                <option disabled="disabled" value="selectClientType">Select client type *</option>
                                <option value="B2C">B2C</option>
                                <option value="B2B">B2B</option>
                            </select>
                            <label>CVR</label>
                            <input type="text" placeholder="CVR" onChange={e => setCVR(e.target.value)}/>
                        </fieldset>

                        <fieldset>
                            <legend>Quest's details</legend>
                            <label>Date</label>
                            <Flatpickr data-enable-time value={date} onChange={(date, dateString) => {setDate(dateString)}} options={{minDate: (new Date()), dateFormat: "D Y-m-d"}} placeholder="Date"/>
                            <label>Number of participants *</label>
                            <input type="text" placeholder="Number of participants *" onChange={e => setParticipants(e.target.value)}/>
                            <label>Payment id (downtown) *</label>
                            <input type="text" placeholder="Payment id (downtown) *" onChange={e => setPaymentId(e.target.value)}/>
                            <label>Quest*</label>
                            <select defaultValue="selectQuest" onChange={e => selectQuest(e.target.value)}>
                                <option disabled="disabled" value="selectQuest">Select quest *</option>
                                {quests.map((val, i) => {
                                    return(<option key={i} value={quests[i].title}>{quests[i].title}</option>);
                                })}
                            </select>
                        </fieldset>
                        <button type="button" className="btn btn-create-order">Create order <FontAwesomeIcon icon={faArrowAltCircleRight}/></button>
                    </div>)}
                </div>

                <div className="box-wide box-orders">
                    <header>Orders</header>
                    <table className="table-orders">
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Payment id</th>
                                <th>Manual entry</th>
                                <th>Client Type</th>
                                <th>Email</th>
                                <th>Telephone</th>
                                <th>Delete</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((val, i) => {
                                return(<tr key={i}>
                                    <td>{orders[i].id}</td>
                                    <td>{orders[i].paymentId || "-"}</td>
                                    <td>{orders[i].manualEntry || "-"}</td>
                                    <td>{orders[i].clientType || "-"}</td>
                                    <td>{orders[i].email || "-"}</td>
                                    <td>{orders[i].telephone || "-"}</td>
                                    <td><button type="button" className="btn" onClick={() => {deleteOrder(i)}}><FontAwesomeIcon icon={faTrash}/></button></td>
                                    <td><button type="button" className="btn" onClick={() => {showDetails(i)}}><FontAwesomeIcon icon={faOutdent}/></button></td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Orders;