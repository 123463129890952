import React from 'react';

function Messages() {
        return (
            <div className="box-content">
                <h1>Quests</h1>
                <div className="box">Quests</div>
            </div>
        );
}

export default Messages;