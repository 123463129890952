import React, {useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';

function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    return (
        <div className="box-login">
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} name="email" placeholder="Email" />
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" className="login" />
            <div className="error">{error}</div>
            <button type="button" className="btn-login" onClick={login}>Authorize <FontAwesomeIcon icon={faSignInAlt}/></button>
        </div>
    );
    
    function login() {  
        axios.post("api/admin/auth", {
            email: email,
            password: password
        }).then((response) => {
            // Remove email and password from form
            setEmail("");
            setPassword("");
        
            // Save the session data to sessionStorage
            const session = response.data.data;
            sessionStorage.username = session.username;
            sessionStorage.accessLevel = session.accessLevel;
            sessionStorage.refreshed = session.refreshed;
            sessionStorage.sessionId = session.sessionId;
            
            // Remove error if there was one from a previous login attempt
            setError("");

            // Go to the Index page
            props.onAuthorization();
        }, (error) => {
            setError("Authorization Failure");
        });
    }
}

export default Login;