import React, {useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowAltCircleRight} from '@fortawesome/free-solid-svg-icons';

function Console() {
    const [sql, setSQL] = useState(null);
    const [responseData, setResponseData] = useState("Execute SQL to see a response");
    const [columnWidth, setColumnWidth] = useState(250);
    const [tableHead, setTableHead] = useState({});
    const [tableRows, setTableRows] = useState([]);
    return (
        <div className="box-content">
            <h1>Console</h1>
            <div className="box">
                <h2>SQL to execute:</h2>
                <textarea className="textarea-sql" onChange={e => setSQL(e.target.value)}></textarea>
                <h2>Result:</h2>
                <div className="box-sql-response" dangerouslySetInnerHTML={{__html: responseData}}></div>
                <span className="description">Max column width:</span>
                <span className="max-col-width">{columnWidth}</span>
                <input type="range" min="1" max="2500" value={columnWidth} onChange={(e) => {setColumnWidth(e.target.value); changeColumnWidth();}} className="slider-col-width"/>
                <button type="button" className="btn btn-execute" onClick={executeSQL}>Execute <FontAwesomeIcon icon={faArrowAltCircleRight}/></button>
            </div>
        </div>
    );
    
    function changeColumnWidth() {
        const styleSheets = document.styleSheets;
        for (let sheet of styleSheets) {
            for (let rule of sheet.cssRules) {
                if (rule.selectorText === "div.cell") {
                    rule.style["maxWidth"] = columnWidth + "px";
                }
            }
        }
    }

    function executeSQL() {
        axios.post("api/admin/sql", {
            "sql": sql,
            "sessionId": sessionStorage.sessionId
        }).then((response) => {
            // Create a response table
            const data = response.data.data;
            const tableHead = data[0];
            let tableRows = [];
            
            for (let i = 1; i < data.length; i++) {
                tableRows.push(data[i]);
            }
            
            let table = "<table>";
            
            // Get table header names
            const headerNames = Object.keys(data[0]);
            let tableHeader = "";
            
            // Add column names to table header
            for (let i = 0; i < headerNames.length; i ++) {
                tableHeader += "<th>" + headerNames[i] + "</th>";
            }
            
            // Add header to table
            table += "<tr>" + tableHeader + "</tr>"
            
            let row;
            
            // Add data rows to the table
            for (let i = 0; i < data.length; i++) {
                // Initialize a clean row
                row = "";
                
                // Get row values
                const values = Object.values(data[i]);
            
                // Add values to the row
                for (let j = 0; j < values.length; j++) {
                    row += '<td><div class="cell">' + values[j] + "</div></td>";
                }
                
                // Add row to the table
                table += "<tr>" + row + "</tr>";
            }
            
            // End the table
            table += "</table>";
            
            // Display response
            setResponseData(table);
            
            // Set session refresh time
            sessionStorage.refreshed = response.data.refreshed;
        }, (error) => {
            setResponseData(error + "<br> Server said: " + JSON.stringify(error.response.data));
        });
    }
}

export default Console;