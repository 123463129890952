import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

function Messages() {
    const [error, setError] = useState("");
    const [messages, setMessages] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get("api/admin/messages", {
            params: {sessionId: sessionStorage.sessionId}
        }).then((response) => {
            let responseData = response.data.data;
            
            // Add message patch error placeholder
            for (let i = 0; i < responseData.length; i++) {
                responseData[i].error = "";
            }

            setError("");
            setMessages(responseData);
            sessionStorage.refreshed = response.data.refreshed;
        }, (error) => {
            if (error.response.status === 404) {
                // Display a 404
                setError("No messages to show");
                return;
            }
            
            // Display an error message
            setError("Could not retrieve messages");
        });
    }, []);
    
    function setAnswered(messageId, index) {
        axios.patch("api/admin/message/" + messageId, {
            answered: "Yes",
            sessionId: sessionStorage.sessionId
        }).then((response) => {
            sessionStorage.refreshed = response.data.refreshed;
            
            // Change status to answered in the dom
            let newMessagesArray = [...messages];
            newMessagesArray.[index].answered = "Yes";
            setMessages(newMessagesArray);
        }, (error) => {         
            // Display an error message
            let newMessagesArray = [...messages];
            newMessagesArray[index].error = "Could not change message status";
            setMessages(newMessagesArray);
        });
    }

    return (
        <div className="box-content">
            <h1>Messages</h1>
            <div className="box box-messages">
                <div className="error">{error}</div>
                {messages ? <div className="box-inline">
                    <table className="messages">
                        <thead>
                            <tr><th>#</th><th>Contact information</th><th>Message text</th><th>Answered</th></tr>
                        </thead>
                        <tbody>
                            {messages.map((message, index) => {
                                return (<tr className="message" key={message.id}>
                                    <td>{message.id}</td>
                                    <td>
                                        <ul className="contact-info">
                                            <li>Name: {message.name}</li>
                                            <li>Telephone:{message.telephone}</li>
                                            <li>Email: <a href={message.email}>{message.email}</a></li>
                                            <li>CVR: {message.cvr ? message.cvr : "-"}</li>
                                            <li>Sent: {message.datetime}</li>
                                        </ul>
                                    </td>
                                    <td className="message-text">{message.message}</td>
                                    <td>
                                        <p>{message.answered}</p>
                                        {message.answered === "No" ? <button type="button" className="btn btn-mini" onClick={() => {setAnswered(message.id, index)}}>Set Answered</button> : ""}
                                        <p className="error">{message.error}</p>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div> : <FontAwesomeIcon icon={faSpinner}/>}
            </div>
        </div>
    );
}

export default Messages;