import React from 'react';

function Restrictions() {
        return (
            <div className="box-content">
                <h1>Restrictions</h1>
                <div className="box">Order restrictions</div>
            </div>
        );
}

export default Restrictions;